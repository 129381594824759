import axios from 'axios'
import LinkWrap from 'components/LinkWrap'
import { navigate } from 'gatsby'
import React, { Component } from 'react'
import { Field, Form } from 'react-final-form'
import { ReactSVG } from 'react-svg'
import { strapi, trFunction } from 'utils/functions'
var hasher = require('wordpress-hash-node')

// TODO: login returns to previous page

class NewAccountForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      postedEmail: null,
      error: null,
      loading: false,
    }
  }

  render() {
    const locale = this.props.data.site.siteMetadata.locale
    const translations = this.props.data.translations
    const tr = (code) => trFunction(code, translations, locale)

    const onSubmit = (values) => {
      this.setState({ error: null, loading: true })

      // check if user already exists
      // check if the email exists
      let urlCheckEmail = strapi.url
      urlCheckEmail += `/users?email=${values.email}`

      axios({
        method: 'get',
        url: urlCheckEmail,
        headers: {
          Authorization: `Bearer ${strapi.token}`,
        },
      }).then((response) => {
        if (response.data.length >= 1) {
          // email already exists
          this.setState({ error: '' })
          setTimeout(() => {
            this.setState({
              postedEmail: values.email,
              error: 'emailExists',
              loading: false,
            })
          }, 500)
        } else {
          // create new user
          let postUser = {
            userType: [values.userType],
            nameFirst: values.nameFirst,
            nameLast: values.nameLast,
            company: values.company,
            phone: values.phone,
            username: values.email,
            email: values.email,
            password: hasher.HashPassword(values.password),
            password2: hasher.HashPassword(values.password),
            dateRegistered: new Date(),
            approved: false,
          }
          let urlPostUser = strapi.url
          urlPostUser += '/users'
          axios({
            method: 'post',
            url: urlPostUser,
            headers: {
              Authorization: `Bearer ${strapi.token}`,
            },
            data: postUser,
          }).then((response) => {
            const createdUser = response.data
            sessionStorage.setItem('loginUser', JSON.stringify(createdUser))

            // send email
            const postObj = {
              // form: JSON.stringify(values, 0, 2),
              form: values,
              locale,
              translations,
            }
            const url =
              'https://stellar.lanternbox.com/email/emails/welcome/email.php'
            const that = this
            axios
              .post(url, postObj)
              .then(function (response) {
                that.setState({ error: null, loading: false })
                navigate('/my-account/welcome')
              })
              .catch(function (error) {})
          })
        }
      })
    }

    const loadingClass = this.state.loading ? 'loading' : ''
    return (
      <div className="woocomerce-form">
        <ul
          className={
            'error-holder-single ' +
            (this.state.error === 'emailExists' ? 'show' : '')
          }
        >
          <li>
            <strong>{tr('FORM_ITEM_ERROR')}</strong>
            {tr('SPACE')}
            {this.state.postedEmail}
            {tr('SPACE')}
            {tr('FORM_REGISTER_ERROR_EMAIL_ALREADY_REGISTERED')}
            <LinkWrap to="/my-account/lost-password">
              {tr('FORM_ITEM_LOST_PASSWORD')}
            </LinkWrap>
          </li>
        </ul>

        <Form
          onSubmit={onSubmit}
          // initialValues={{ userType: "Trade" }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <p className="form-row form-row-wide">
                <label className="radio-custom">
                  <Field
                    name="userType"
                    component="input"
                    type="radio"
                    value="Trade"
                    required
                  />
                  {tr('FORM_ITEM_TRADE')}
                  <span className="checkmark" />
                </label>
                <label className="radio-custom">
                  <Field
                    name="userType"
                    component="input"
                    type="radio"
                    value="Press"
                    required
                  />
                  {tr('FORM_ITEM_PRESS')}
                  <span className="checkmark" />
                </label>
              </p>
              <p className="form-row form-row-first">
                <Field
                  name="nameFirst"
                  component="input"
                  type="text"
                  placeholder={`${tr('FORM_ITEM_FIRST_NAME')}*`}
                  required
                />
              </p>
              <p className="form-row form-row-last">
                <Field
                  name="nameLast"
                  component="input"
                  type="text"
                  placeholder={`${tr('FORM_ITEM_LAST_NAME')}*`}
                  required
                />
              </p>
              <p className="form-row form-row-wide">
                <Field
                  name="company"
                  component="input"
                  type="text"
                  placeholder={`${tr('FORM_ITEM_COMPANY')}*`}
                  required
                />
              </p>
              <p className="form-row form-row-wide">
                <Field
                  name="phone"
                  component="input"
                  type="text"
                  placeholder={tr('FORM_ITEM_PHONE')}
                />
              </p>
              <p className="form-row form-row-wide">
                <Field
                  name="email"
                  component="input"
                  type="email"
                  placeholder={`${tr('FORM_ITEM_EMAIL')}*`}
                  required
                />
              </p>
              <p className="form-row form-row-wide">
                <Field
                  name="password"
                  component="input"
                  type="password"
                  placeholder={`${tr('FORM_ITEM_PASSWORD')}*`}
                  required
                />
              </p>
              <div className={`submit-wrap full ${loadingClass}`}>
                <span className="loader">
                  <ReactSVG src="/images/loader.svg" />
                </span>
                <input
                  className="woocommerce-Button button"
                  type="submit"
                  value={tr('FORM_ITEM_REGISTER')}
                  disabled={this.state.loading}
                />
              </div>
            </form>
          )}
        />
      </div>
    )
  }
}

export default NewAccountForm
