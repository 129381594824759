import React from 'react'
import NewAccountForm from 'components/forms/newAccount'
import Layout from 'components/layout'
import Title from 'components/title'
import { trFunction } from 'utils/functions'
import { graphql } from 'gatsby'

const NewAccount = ({ data }) => {
  const locale = data.site.siteMetadata.locale
  const translations = data.translations
  const tr = (code) => trFunction(code, translations, locale)
  const page = data.thisPage

  return (
    <Layout>
      <div id="content" className="page-template-page-account">
        <Title title={page.title} />
        <section className="page-content wrap">
          <NewAccountForm tr={tr} data={data} />
        </section>
      </div>
    </Layout>
  )
}

export default NewAccount

export const newAccountQuery = graphql`
  query newAccountQuery {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "registration" }) {
      title
      slug
    }
  }
`
